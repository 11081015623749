import { ProtectedElement } from "../ProtectedRoute";
import { Loadable } from "../../components/Loadable/Loadable";
import { lazy } from "react";
import {
  ASSET_MANAGER,
  CROSS_SITE_VIEWER,
  LOCUS_ASSET_MANAGER,
  LOCUS_TENANT,
  VIEWER,
} from "../../utils/constants/user";
import componentLoader from "../../utils/helpers/componentLoader";

const Robots = Loadable(
  lazy(() =>
    componentLoader(() => import("views/asset-management/robots/RobotList")),
  ),
);
const RobotOverview = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/asset-management/robots/RobotOverview"),
    ),
  ),
);
const RobotCreate = Loadable(
  lazy(() =>
    componentLoader(() => import("views/asset-management/robots/RobotList")),
  ),
);
const Chargers = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/asset-management/chargers/ChargerList"),
    ),
  ),
);
const ChargerOverview = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/asset-management/chargers/ChargerOverview"),
    ),
  ),
);
const ChargerCreate = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/asset-management/chargers/ChargerList"),
    ),
  ),
);
const Devices = Loadable(
  lazy(() =>
    componentLoader(() => import("views/asset-management/devices/DeviceList")),
  ),
);
const DeviceOverview = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/asset-management/devices/DeviceOverview"),
    ),
  ),
);
const DeviceCreate = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/asset-management/devices/DeviceCreate"),
    ),
  ),
);
const DeviceEdit = Loadable(
  lazy(() =>
    componentLoader(() => import("views/asset-management/devices/DeviceEdit")),
  ),
);

export const AssetManagementRoutes = {
  path: "asset-management",
  children: [
    {
      path: "robots",

      breadcrumb: {
        display: "Robots",
      },
      children: [
        {
          path: "",
          element: (
            <ProtectedElement
              element={Robots}
              allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
        {
          path: ":id",
          element: (
            <ProtectedElement
              element={RobotOverview}
              allowedGroups={[LOCUS_TENANT]}
            />
          ),
        },
        {
          path: "create",
          element: (
            <ProtectedElement
              element={RobotCreate}
              allowedGroups={[LOCUS_ASSET_MANAGER]}
            />
          ),
          breadcrumb: {
            display: "Create",
          },
        },
      ],
    },
    {
      path: "chargers",

      breadcrumb: {
        display: "Chargers",
      },
      children: [
        {
          path: "",
          element: (
            <ProtectedElement
              element={Chargers}
              // https://locusrobotics.atlassian.net/browse/HUB-1614 && HUB-1627
              allowedGroups={[LOCUS_ASSET_MANAGER]}
              // allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
        {
          path: ":id",
          element: (
            <ProtectedElement
              element={ChargerOverview}
              // https://locusrobotics.atlassian.net/browse/HUB-1614 && HUB-1627
              allowedGroups={[LOCUS_ASSET_MANAGER]}
              // allowedGroups={[LOCUS_TENANT]}
            />
          ),
        },
        {
          path: "create",
          element: (
            <ProtectedElement
              element={ChargerCreate}
              allowedGroups={[LOCUS_ASSET_MANAGER]}
            />
          ),
          breadcrumb: {
            display: "Create",
          },
        },
      ],
    },
    {
      path: "devices",

      breadcrumb: {
        display: "Devices",
      },
      children: [
        {
          path: "",
          element: (
            <ProtectedElement
              element={Devices}
              // https://locusrobotics.atlassian.net/browse/HUB-1614 && HUB-1627
              allowedGroups={[LOCUS_ASSET_MANAGER, ASSET_MANAGER]}
              // allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
            />
          ),
        },
        {
          path: ":id",
          children: [
            {
              path: "",
              element: (
                <ProtectedElement
                  element={DeviceOverview}
                  // https://locusrobotics.atlassian.net/browse/HUB-1614 && HUB-1627
                  allowedGroups={[LOCUS_ASSET_MANAGER, ASSET_MANAGER]}
                  // allowedGroups={[LOCUS_TENANT, CROSS_SITE_VIEWER, VIEWER]}
                />
              ),
            },
            {
              path: "edit",
              element: (
                <ProtectedElement
                  element={DeviceEdit}
                  allowedGroups={[LOCUS_ASSET_MANAGER, ASSET_MANAGER]}
                />
              ),
              breadcrumb: {
                display: "Edit",
              },
            },
          ],
        },
        {
          path: "create",
          element: (
            <ProtectedElement
              element={DeviceCreate}
              allowedGroups={[LOCUS_ASSET_MANAGER]}
            />
          ),
          breadcrumb: {
            display: "Create",
          },
        },
      ],
    },
  ],
};
